// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-case-studies-kenyon-review-jsx": () => import("/opt/build/repo/src/components/case-studies/kenyon-review.jsx" /* webpackChunkName: "component---src-components-case-studies-kenyon-review-jsx" */),
  "component---src-components-case-studies-kenyon-collegian-web-jsx": () => import("/opt/build/repo/src/components/case-studies/kenyon-collegian-web.jsx" /* webpackChunkName: "component---src-components-case-studies-kenyon-collegian-web-jsx" */),
  "component---src-components-case-studies-kenyon-collegian-logo-jsx": () => import("/opt/build/repo/src/components/case-studies/kenyon-collegian-logo.jsx" /* webpackChunkName: "component---src-components-case-studies-kenyon-collegian-logo-jsx" */),
  "component---src-components-case-studies-forty-north-map-jsx": () => import("/opt/build/repo/src/components/case-studies/forty-north-map.jsx" /* webpackChunkName: "component---src-components-case-studies-forty-north-map-jsx" */),
  "component---src-components-case-studies-collegian-magazine-web-jsx": () => import("/opt/build/repo/src/components/case-studies/collegian-magazine-web.jsx" /* webpackChunkName: "component---src-components-case-studies-collegian-magazine-web-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

